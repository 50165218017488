<template>
  <div class="flex flex-col w-full mt-6 px-15 lg:px-4 sm-ws:px-10">
    <div
      class="
        flex
        font-extrabold
        text-promy-gray-650
        leading-2
        text-promy-purple-400 text-promy-2xl
        font-main
        sm:text-lg
        xl:mb-12
        lg:mb-4
      "
    >
      <span>Outils promoteur :</span>
      <span class="font-normal ml-1">Pige foncière</span>
    </div>
    <router-view
      :status="status"
      :getStatusColor="getStatusColor"
    ></router-view>
  </div>
</template>

<script>
export default {
  data() {
    return {
      status: ['3', '1', '2'],
      isFetching: false,
      current_page: 1,
      demandes: [
        {
          status: 1,
          user: 'test',
          date: '01/01/2020',
          update: '01/01/2020',
          nom_offre: 'offre',
        },
      ],
    }
  },
  methods: {
    getStatusColor(status) {
      switch (status) {
        case '3':
          return 'red'
        case '1':
          return 'orange-100'
        case '2':
          return 'green-100'
      }
    },
  },
}
</script>
